import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

{/* <StaticImage
  src="../images/gatsby-astronaut.png"
  width={300}
  quality={95}
  formats={["auto", "webp", "avif"]}
  alt="A Gatsby astronaut"
  style={{ marginBottom: `1.45rem` }}
/>
<p>
  <Link to="/page-2/">Go to page 2</Link> <br />
  <Link to="/using-typescript/">Go to "Using TypeScript"</Link> <br />
  <Link to="/using-ssr">Go to "Using SSR"</Link> <br />
  <Link to="/using-dsg">Go to "Using DSG"</Link>
</p> */}

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <h1 className="text-3xl font-bold underline">
      Hello world!
    </h1>
  </Layout>
)

export default IndexPage
